// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}contractModuleManage/contractModuleList`

// 编辑
const editURL = `${API_CONFIG.butlerBaseURL}contractModuleManage/addEditContractModule`
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}contractModuleManage/contractModuleInfo`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=show`

// 获取通用模板
const commonTemplateURL = `${API_CONFIG.butlerBaseURL}contractModuleManage/getContractCommonLabel`

// 操作人 select2
const getUserListURL = `${API_CONFIG.butlerBaseURL}agreement/user/search/keyword`

export {
  getListURL,
  editURL,
  queryURL,
  uploadURL,
  commonTemplateURL,
  getUserListURL
}
