<template>
  <div class="Contract-templateList">
    <list
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="合同模板名称" v-model="searchParams.moduleName" />
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-select label="状态" v-model="searchParams.moduleStatus" :options="statusOps" />
        <v-select2 label="创建人" v-model="searchParams.inuser" v-bind="updateUserParams" />
        <v-select2 label="修改人" v-model="searchParams.updateUser" v-bind="updateUserParams" />
        <v-datepicker label="创建时间" type="rangedatetimer" :startTime.sync="searchParams.createStartTime" :endTime.sync="searchParams.createEndTime" />
        <v-datepicker label="修改时间" type="rangedatetimer" :startTime.sync="searchParams.updateStartTime" :endTime.sync="searchParams.updateEndTime" />
      </template>
      <template #operateSlot="scope">
        <!-- <v-button text="编辑" type="text" permission="update" @click="update(scope.row)" /> -->
        <v-button text="编辑" type="text"  @click="update(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { getListURL, getUserListURL } from './api'
import { statusMap, setStatusOps } from './map'

export default {
  name: 'Contract-templateList',
  data () {
    return {
      searchUrl: getListURL,
      statusOps: setStatusOps(1),
      communityParams,
      searchParams: {
        moduleName: '',
        communityId:'',
        moduleStatus: undefined,
        inuser:'',
        updateUser:'',
        createStartTime: '',
        createEndTime:'',
        updateStartTime:'',
        updateEndTime:''
      },
      updateUserParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      headers: [
        {
          prop: 'moduleName',
          label: '合同模板名称'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.moduleStatus]
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        },
        {
          prop: 'updateUserName',
          label: '修改人'
        },
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'contractTemplateForm'
      })
    },
    update (row) {
      this.$router.push({
        name: 'contractTemplateForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
